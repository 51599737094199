const tableColumns = {
  1: [
    {
      title: '人员',
      dataIndex: 'staffName',
      key: 'staffName',
      slots: {
        title: 'staffName',
        customRender: 'staffName'
      },
      width: 300
    },
    {
      title: '账号',
      dataIndex: 'mobile',
      key: 'mobile',
      slots: {
        title: 'mobile',
        customRender: 'mobile'
      }
    },
    {
      title: '原部门',
      dataIndex: 'deptName',
      key: 'deptName',
      slots: {
        title: 'deptName',
        customRender: 'deptName'
      }
    }
  ],
  2: [
    {
      title: '人员',
      dataIndex: 'staffName',
      key: 'staffName',
      slots: {
        title: 'staffName',
        customRender: 'staffName'
      }
    },
    {
      title: '账号',
      dataIndex: 'mobile',
      key: 'mobile',
      slots: {
        title: 'mobile',
        customRender: 'mobile'
      }
    }
  ],
  3: [
    {
      title: '围栏名称',
      dataIndex: 'railName',
      key: 'railName',
      slots: {
        title: 'railName',
        customRender: 'railName'
      }
    },
    {
      title: '围栏中心点',
      dataIndex: 'railAddress',
      key: 'railAddress',
      slots: {
        title: 'railAddress',
        customRender: 'railAddress'
      }
    },
    {
      title: '围栏半径',
      dataIndex: 'railRadius',
      key: 'railRadius',
      align: 'left',
      slots: { customRender: 'railRadius' },
      width: 150
    }
  ],
  4: [
    {
      title: '印章',
      dataIndex: 'sealName',
      key: 'sealName',
      width: 150,
      slots: {
        title: 'sealName',
        customRender: 'sealName'
      }
    },
    {
      title: '印章保管员（前）',
      dataIndex: 'oldStaffInfoList',
      key: 'oldStaffInfoList',
      slots: {
        title: 'oldStaffInfoList',
        customRender: 'oldStaffInfoList'
      },
      width: 300
    },
    {
      title: '印章保管员（后）',
      dataIndex: 'newStaffInfoList',
      key: 'newStaffInfoList',
      align: 'left',
      slots: { customRender: 'newStaffInfoList' },
      width: 300
    }
  ],
  5: [
    {
      title: '印章',
      dataIndex: 'sealName',
      key: 'sealName',
      width: 150,
      slots: {
        title: 'sealName',
        customRender: 'sealName'
      }
    },
    {
      title: '数据查看人（前）',
      dataIndex: 'oldStaffInfoList',
      key: 'oldStaffInfoList',
      slots: {
        title: 'oldStaffInfoList',
        customRender: 'oldStaffInfoList'
      },
      width: 300
    },
    {
      title: '数据查看人（后）',
      dataIndex: 'newStaffInfoList',
      key: 'newStaffInfoList',
      align: 'left',
      slots: { customRender: 'newStaffInfoList' },
      width: 300
    }
  ],
  6: [
    {
      title: '印章',
      dataIndex: 'sealName',
      key: 'sealName',
      width: 150,
      slots: {
        title: 'sealName',
        customRender: 'sealName'
      }
    },
    {
      title: '特权用印人（前）',
      dataIndex: 'oldStaffInfoList',
      key: 'oldStaffInfoList',
      slots: {
        title: 'oldStaffInfoList',
        customRender: 'oldStaffInfoList'
      },
      width: 300
    },
    {
      title: '特权用印人（后）',
      dataIndex: 'newStaffInfoList',
      key: 'newStaffInfoList',
      align: 'left',
      slots: { customRender: 'newStaffInfoList' },
      width: 300
    }
  ],
  7: [
    {
      title: '序号',
      dataIndex: 'idIndex',
      key: 'idIndex',
      slots: { customRender: 'idIndex' },
      width: 80
    },
    {
      title: '姓名',
      dataIndex: 'staffName',
      key: 'staffName',
      slots: {
        title: 'staffName',
        customRender: 'staffName'
      },
      width: 120
    },
    {
      title: '部门',
      dataIndex: 'departmentName',
      key: 'departmentName',
      slots: {
        title: 'departmentName',
        customRender: 'departmentName'
      },
      width: 200
    },
    {
      title: '当前数据范围',
      dataIndex: 'currentDataPermissionStr',
      key: 'currentDataPermissionStr',
      slots: {
        title: 'currentDataPermissionStr',
        customRender: 'currentDataPermissionStr'
      },
      width: 120
    }
  ]
}

const customKeys = {
  1: [
    {
      key: 'staffName',
      len: 12,
      maxWidth: 168
    }
  ],
  3: [
    {
      key: 'railName',
      len: 8,
      maxWidth: 112
    },
    {
      key: 'railAddress',
      len: 8,
      maxWidth: 112
    }
  ],
  4: [
    {
      key: 'oldStaffInfoList',
      customText: text => {
        text = text?.reduce(
          (prev, cur) => {
            const staffName = cur.staffName.length > 6 ? cur.staffName.slice(0, 6) + '...' : cur.staffName
            prev.newText += '、' + staffName
            prev.allText += '、' + cur.staffName
            return prev
          },
          { newText: '', allText: '' }
        )
        return {
          newText: text ? text.newText?.slice(1) : '',
          allText: text ? text.allText?.slice(1) : ''
        }
      },
      len: 16,
      maxWidth: 224
    },
    {
      key: 'newStaffInfoList',
      customText: text => {
        text = text?.reduce(
          (prev, cur) => {
            const staffName = cur.staffName.length > 6 ? cur.staffName.slice(0, 6) + '...' : cur.staffName
            prev.newText += '、' + staffName
            prev.allText += '、' + cur.staffName
            return prev
          },
          { newText: '', allText: '' }
        )
        return {
          newText: text ? text.newText?.slice(1) : '',
          allText: text ? text.allText?.slice(1) : ''
        }
      },
      len: 16,
      maxWidth: 224
    }
  ],
  7: [
    {
      key: 'staffName',
      len: 6,
      maxWidth: 84
    },
    {
      key: 'departmentName',
      len: 10,
      maxWidth: 140
    },
    {
      key: 'currentDataPermissionStr',
      len: 10,
      maxWidth: 140
    }
  ]
}

const tablePagination = {
  1: {
    pageSize: 5
  },
  2: {
    pageSize: 5
  },
  3: {
    pageSize: 5
  },
  4: {
    pageSize: 5
  },
  5: {
    pageSize: 5
  },
  6: {
    pageSize: 5
  },
  7: {
    pageSize: 5
  }
}

export { tableColumns, customKeys, tablePagination }
