import { reactive } from 'vue'
import { useStore } from 'vuex'
import { tableColumns, customKeys, tablePagination } from './columns'
import gethashcode from './hashCode'
import { detailTypeEnums, iconList, noOldValueEnums, noOldValueLabelCss, labelStyleType } from './data'

const useOperationLog = () => {
  const store = useStore()

  const contentList = reactive([
    {
      key: 'operatorName',
      label: '操作人：',
      value: '李相赫',
      overflow: true,
      maxWidth: 260,
      len: 18
    },
    {
      key: 'createTime',
      label: '操作时间：',
      value: '2021-01-28 15:00:00',
      overflow: false
    },
    { key: 'moduleName', label: '操作模块：', value: '流程名称管理', overflow: false },
    {
      key: 'content',
      label: '操作内容：',
      value: '这里是操作内容，显示看长度，显示看长度，考虑考虑考虑看扣扣了',
      overflow: true,
      maxWidth: 260,
      len: 18
    }
  ])

  const globaViewlId = [43, 44]

  const handleContentList = data => {
    contentList.map(item => {
      item.value = data[item.key]
    })
  }

  //角色权限tree
  const roleLimits = val => {
    const data = JSON.parse(val)
    return {
      power: data.selected,
      data: data.all
    }
  }

  //获取表格columnsKey
  const getTableColumnsKey = type => {
    let columnsKey
    let customKey
    let paginationKey
    switch (type) {
      case 5:
        columnsKey = customKey = paginationKey = 7
        break
      case 23:
        columnsKey = customKey = paginationKey = 1
        break
      case 24:
        columnsKey = customKey = paginationKey = 2
        break
      case 61:
        columnsKey = customKey = paginationKey = 3
        break

      case 65:
      case 118:
      case 121:
        columnsKey = customKey = paginationKey = 4
        break
      case 66:
        customKey = 4
        columnsKey = 5
        paginationKey = 5
        break
      case 67:
      case 120:
      case 123:
        customKey = 4
        columnsKey = 6
        paginationKey = 6
        break
      default:
        columnsKey = customKey = paginationKey = 1
    }

    return { columnsKey, customKey, paginationKey }
  }

  //表格
  const TableFormat = (val, mouduleType) => {
    console.log('表格接收的参数', val, mouduleType)
    const data = JSON.parse(val)
    console.log(data, tableColumns, customKeys, data, '---------表格')
    if (Array.isArray(data))
      data.map((item, index) => {
        item.idIndex = index > 8 ? index + 1 : `0${index + 1}`
      })
    const { columnsKey, customKey, paginationKey } = getTableColumnsKey(mouduleType)
    return {
      data: data || [],
      columns: tableColumns[columnsKey] || [],
      customKeys: customKeys[customKey] || [],
      pagination: tablePagination[paginationKey] || false,
      scroll: {
        x: 300
      }
    }
  }

  //图片
  const imgData = val => {
    console.log(val)
    if (val) {
      return [val]
    }
    return []
  }

  //字符串数组
  const listText = val => {
    const valList = val?.trim() ? JSON.parse(val) : []
    return valList
  }

  //根据类型获取值
  const getValue = (key, { item, mouduleType }) => {
    let val = ''
    switch (item.type) {
      case 2:
        val = item[key]
        break
      case 3:
        val = imgData(item[key])
        break
      case 4:
        val = iconList[item[key]]
        break
      case 5:
        val = TableFormat(item[key], mouduleType)
        break
      case 8:
        val = roleLimits(item[key])
        break
      case 9:
        val = listText(item[key])
        break
    }
    return val
  }

  //范围用印&录制视频
  const getChildSelect = (name, data) => {
    let select = []
    if (name == '常规盖印') {
      if (data.sealDiscern == 1) select.push(1)
      if (data.videoType == 1) select.push(2)
    }
    if (name == '连续盖印') {
      //兼容老数据 continuousSealDiscern&continuousVideoType 没有的情况下
      if (data.continuousSealDiscern == 1 || data.continuousSealDiscern == undefined) select.push(1)
      if (data.continuousVideoType == 1 || data.continuousVideoType == undefined) select.push(2)
    }

    if (name == '远程盖印') {
      //兼容老数据 remoteSealDiscern&remoteVideoType 没有的情况下
      if (data.remoteSealDiscern == 1 || data.remoteSealDiscern == undefined) select.push(1)
      if (data.remoteVideoType == 1 || data.remoteVideoType == undefined) select.push(2)
    }

    return select
  }

  //表单
  const handleForm = data => {
    console.log(data)

    const logFormItems = [
      { detail: JSON.parse(data.details.oldValue), basic: data.oldParam },
      { detail: JSON.parse(data.details.newValue), basic: data.newParam }
    ]

    logFormItems.forEach(val => {
      val.detail?.map(item => {
        item.selectId = gethashcode()
        if (item.configName === 'SealInfo' && item.props.items.length === 3 && !item.props.items[2].props.stamp) {
          item.props.items[2].props = {
            requirred: true,
            stamp: [
              { title: '申请人', isCheck: false },
              { title: '保管员', isCheck: true }
            ]
          }
        }
        if (item.configName == 'Remote') {
          if (Array.isArray(item.props.options)) {
            item.props.options = item.props.options.map(item => {
              return {
                ...item,
                childSelect: getChildSelect(item.name, val.basic)
              }
            })
          }
          console.log(item)
        }
      })
    })

    console.log(logFormItems, '-------------')
    store.commit('setLogFormItems', logFormItems)
  }

  //label
  const getFiledName = data => {
    let fieldName = data.fieldName
    switch (data.type) {
      //表格label不需要冒号
      case 5:
        break
      default:
        fieldName += '：'
    }
    return fieldName
  }

  //labelstyle
  const getLabelStyle = (data, mouduleType) => {
    if (noOldValueEnums.includes(mouduleType)) {
      return noOldValueLabelCss
    }

    //角色权限详情单独设置样式
    if ([5].includes(mouduleType) && !labelStyleType[data.type]) {
      return {
        width: '100px'
      }
    }
    //表格标题左对齐
    return labelStyleType[data.type]
  }

  //流程设计
  const handleProcess = data => {
    console.log(data)

    const designProcess = JSON.parse(data.details.newValue)
    store.commit('mergeBasicInfo', data.newParam)
    store.commit('setDesignProcess', designProcess)
  }

  //自定义表单数据与流程设计
  const handleFormDetail = (data, mouduleType) => {
    handleContentList(data)
    const detail = JSON.parse(data.details)
    console.log(detail)
    mouduleType == 43 ? handleForm(detail) : handleProcess(detail)
    store.commit('setSelectFormItem', null)
    store.commit('setIsPreview', true)
    return {
      contentList,
      details: {}
    }
  }

  //其他操作日志详情 左右对比布局
  const handleLARDetacil = (data, mouduleType) => {
    handleContentList(data)
    const detail = JSON.parse(data.details)

    console.log(detail, '------数据-----')
    let newList = []
    let oldList = []
    const newTitle = noOldValueEnums.includes(mouduleType) ? '操作内容' : '修改后'
    const oldTitle = '修改前'

    detail?.map(item => {
      //新数据
      newList.push({
        label: getFiledName(item),
        type: detailTypeEnums[item.type],
        labelStyle: getLabelStyle(item, mouduleType),
        value: getValue('newValue', { item, mouduleType }),
        isChange: item.change
      })
      //只展示修改后数据的不需要添加oldList
      if (noOldValueEnums.includes(mouduleType)) return
      //旧数据
      oldList.push({
        label: getFiledName(item),
        type: detailTypeEnums[item.type],
        labelStyle: getLabelStyle(item, mouduleType),
        value: getValue('oldValue', { item, mouduleType })
      })
    })
    console.log({ newList, oldList }, '数据')
    return { contentList, details: { oldList, newList, newTitle, oldTitle } }
  }

  const handleDetailData = (data, mouduleType) => {
    let fn = globaViewlId.includes(mouduleType) ? handleFormDetail : handleLARDetacil
    return fn(data, mouduleType)
  }

  return {
    handleDetailData,
    globaViewlId
  }
}

export default useOperationLog
